import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'

import { Section, Title, Text, Box, ButtonIcon } from '../../components/Core'
import PageWrapper from '../../components/PageWrapper'
import Screenshot from '../../components/Screenshot'
import NextLink from '../../components/NextLink'
import WebsiteLink from '../../components/WebsiteLink'
import Slides from '../../components/Slides'
import Contact from '../../sections/common/Contact'
import Hero from '../../sections/common/Hero'
import { Keys, Key } from '../../sections/keys/Keys'

import favicon from '../../assets/images/aa/favicon.png'
import matchdocs from '../../assets/images/pd/match-doctors.png'

import homepage from '../../assets/images/ajc/homepage.jpg'
import algoliasearch from '../../assets/images/ajc/algolia-search.jpg'
import doctorprofiles from '../../assets/images/ajc/doctor-profile.jpg'
import footankle from '../../assets/images/ajc/foot-ankle.jpg'
import jointevaluationq from '../../assets/images/ajc/joint-evaluation-question.jpg'
import jointevaluation from '../../assets/images/ajc/joint-evaluation.jpg'
import videos from '../../assets/images/ajc/videos.jpg'

const WorkSingle = () => {
    return (
        <>
            <PageWrapper>
                <Hero className="mt-lg-5">
                    <Container>
                        <Row>
                            <Col lg="8">
                                <Text variant="tag">Full Stack</Text>
                                <Title mb="0.5em">Atlantic Joint Center</Title>
                                <Text variant="p" mb="1.6em">
                                    The Atlantic Joint Center was smaller
                                    project tasked to me to create a smaller
                                    satellite site of the primary website.
                                </Text>
                                <Text variant="p" mb="1.6em">
                                    This website reused the same template as the
                                    main site (Atlantic Spine Center) but
                                    designed for joints and sports injurie
                                    healthcare.
                                </Text>
                            </Col>
                            <Col lg="4">
                                <img
                                    src={matchdocs}
                                    alt="Website Analytics"
                                    className="img-fluid w-100"
                                />
                            </Col>
                        </Row>
                    </Container>
                </Hero>

                <div className="mt-lg-3">
                    <Container>
                        <Row>
                            <Col lg="4" className="mb-4 mb-lg-0">
                                <Text variant="tag">Client</Text>
                                <Title variant="cardBig" className="mt-3">
                                    Atlantic Joint Center
                                </Title>
                            </Col>
                            <Col lg="4" className="mb-4 mb-lg-0">
                                <Text variant="tag">Time</Text>
                                <Title variant="cardBig" className="mt-3">
                                    2019
                                </Title>
                            </Col>
                            <Col lg="4">
                                <Text variant="tag">Website</Text>

                                <Title variant="cardBig" className="mt-3">
                                    <WebsiteLink
                                        src={favicon}
                                        href="https://www.atlanticjointcenter.com"
                                    >
                                        atlanticjointcenter.com
                                    </WebsiteLink>
                                </Title>
                            </Col>
                        </Row>
                    </Container>
                </div>

                <Section className="mt-lg-5">
                    <Container>
                        <Row>
                            <Col lg="5" className="mb-5 pr-lg-5">
                                <img
                                    src={matchdocs}
                                    alt="Match Docs"
                                    className="img-fluid w-100"
                                />
                            </Col>
                            <Col lg="7" className="mb-5 pl-lg-5">
                                <Title variant="cardBig" className="mb-4">
                                    Cloning a Thoroughbred
                                </Title>
                                <Text variant="p" mb="1.6em">
                                    The website for Atlantic Spine Center was
                                    our companies thoroughbred in medical
                                    marketing.
                                </Text>
                                <Text variant="p" mb="1.6em">
                                    The client wanted the same enterprise class
                                    website but instead to focus on joint pain
                                    and sports medicine.
                                </Text>
                                <Text variant="p" mb="1.6em">
                                    It didn't take very long to clone and reuse
                                    the main website template and components to
                                    build this website clone.
                                </Text>
                            </Col>
                        </Row>
                    </Container>
                </Section>
                <Keys mt="-5%">
                    <Key name="gatsby" title="01. Gatsby" left>
                        Certainly a key to the websites cloneability was the
                        fact it was built using gatsby. Page templates were
                        reused with only minor changes to the styling and brand
                        to give the website an entirely different look.
                    </Key>

                    <Key name="docker" title="02. Docker">
                        Buiding static sites inside docker containers has become
                        a staple of my website and application builds.
                    </Key>

                    <Key name="react" title="03. React" left>
                        Not a surprise that React keeps making the list of key
                        developments.I switched to react after building sites
                        for over 2 decades using Jquery combined with an html
                        template engine like Twig.
                    </Key>

                    <Key name="algolia" title="04. Algolia">
                        Powering the sites search was algolia again. Cloning the
                        integration was a snap and only required a new index at
                        algolia and the site search was fully operation.
                    </Key>

                    <Key name="googlecloud" title="05. Google Cloud" left>
                        With widespread support of docter containers and
                        container registries came much simplier methods of
                        deploying my builds. Moving containers from AWS to
                        Google was a snap.
                    </Key>

                    <Key name="aws" title="06. Amazon Web Services">
                        Prior to the move to Google Cloud, AWS hosted most of
                        the sites I built using Docker Swarm and I managed an HA
                        Proxy service that distributed the sites traffic.
                    </Key>
                </Keys>

                <Section mt="22%" bg="dark">
                    <Container>
                        <Row
                            css={`
                                margin-top: -35%;
                            `}
                        >
                            <Col xs="12" className="mb-5">
                                <Slides>
                                    <Screenshot
                                        src={homepage}
                                        alt="Atlantic Joint Center Homepage"
                                    />
                                    <Screenshot
                                        src={doctorprofiles}
                                        alt="Doctor Profiles"
                                    />
                                    <Screenshot
                                        src={algoliasearch}
                                        alt="Algolia Search"
                                    />
                                    <Screenshot
                                        src={jointevaluation}
                                        alt="Joint Evaluation"
                                    />
                                    <Screenshot
                                        src={jointevaluationq}
                                        alt="Joint Evaluation Question"
                                    />
                                    <Screenshot
                                        src={footankle}
                                        alt="Foot and Ankle Landing Page"
                                    />
                                    <Screenshot
                                        src={videos}
                                        alt="Joint Pain Animation Videos"
                                    />
                                </Slides>
                            </Col>
                        </Row>

                        <div className="text-center mt-lg-5">
                            <Text
                                variant="tag"
                                className="mb-1"
                                color="lightShade"
                            >
                                Next Project
                            </Text>
                            <NextLink to="/works/pain-diagnosed/">
                                Pain Diagnosed
                            </NextLink>
                        </div>
                    </Container>
                </Section>

                <Box py={4}>
                    <Contact />
                </Box>
            </PageWrapper>
        </>
    )
}
export default WorkSingle
